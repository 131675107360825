import React from "react";
import { useAriaId } from "../hooks/useAriaId";
import { Button } from "../Button";
import { Wrapper, Input, Label } from "./style";

export type QuantitySelectorProps = {
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  /**
   * Label you want to associate with the quantity selector.
   * This label should give information about this input.
   */
  label: string;
  minValue?: number;
  maxValue?: number;
  className?: string;
};

function getValueInsideMinMax(currentValue: number, minValue?: number, maxValue?: number) {
  if (maxValue != null && currentValue > maxValue) {
    const oneDigitValue = currentValue % 10;
    return Math.min(oneDigitValue, maxValue);
  }
  if (minValue != null && currentValue < minValue) {
    const oneDigitValue = currentValue % 10;
    return Math.max(oneDigitValue, minValue);
  }
  return currentValue;
}

export function QuantitySelector(props: QuantitySelectorProps) {
  const { quantity, setQuantity, className, label, maxValue, minValue } = props;
  const inputId = useAriaId();

  function changeQuantity(operator: "increase" | "decrease") {
    setQuantity((prevState) => {
      if (operator === "increase" && ((maxValue != null && prevState < maxValue) || maxValue == null))
        return prevState + 1;
      if (operator === "decrease" && ((minValue != null && prevState > minValue) || minValue == null))
        return prevState - 1;
      return prevState;
    });
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const currentValue = e.target.valueAsNumber;
    if (isNaN(currentValue)) return setQuantity(0);

    setQuantity(getValueInsideMinMax(currentValue, minValue, maxValue));
  }

  return (
    <Wrapper className={className}>
      <Button
        variant="secondary"
        title="Fjern"
        isIconButton
        icon="minus"
        onClick={() => changeQuantity("decrease")}
        aria-hidden="true"
        disabled={minValue != null && quantity <= minValue}
        tabIndex={-1}
      />
      <Label htmlFor={inputId}>{label}</Label>
      <Input id={inputId} type="number" min={minValue} max={maxValue} value={quantity} onChange={onChange} />
      <Button
        variant="secondary"
        title="Legg til"
        isIconButton
        icon="plus"
        onClick={() => changeQuantity("increase")}
        aria-hidden="true"
        disabled={maxValue != null && quantity >= maxValue}
        tabIndex={-1}
      />
    </Wrapper>
  );
}
