import { track } from "@telia-no-min-side/utils";
import { NoBox, TeliaPlayBox } from "components/product-shopping-cards";
import { Recording } from "components/product-shopping-cards/Recording";
import { SmartWifiExtender } from "pages/broadband/fixed/buy-hardware/internet-equipment/SmartWifiExtender";
import { SmartWifiRouter } from "pages/broadband/fixed/buy-hardware/internet-equipment/SmartWifiRouter";
import { TeliaWifiRouter } from "pages/broadband/fixed/buy-hardware/internet-equipment/TeliaWifiRouter";
import { useFlexOrder } from "pages/flex/context/FlexOrderProvider";
import { getAvailableProducts } from "pages/flex/utils/getAvailableProducts";
import { TRACK_EVENT } from "util/constants/googleAnalytics";

type Props = {
  requirementSelectHandler: ({ productId }: { productId: number }) => void;
};

export function RequirementProducts({ requirementSelectHandler }: Props) {
  const { pageRequirement, pageSelectedRequirements, quantityExtender, setQuantityExtender } = useFlexOrder();
  const { pushGenericTrackingEvent } = track.useEventTracking();
  const { teliaWifiRouter, smartWifiRouter, smartWifiExtender, noBox, teliaPlayBox, recording } = getAvailableProducts(
    pageRequirement?.options || []
  );

  const pushTrackingEvent = (itemText: string) =>
    pushGenericTrackingEvent({
      ui_item_action: TRACK_EVENT.ITEM_ACTION.SELECT_ITEM,
      ui_item_context: TRACK_EVENT.ITEM_CONTEXT.FLEX,
      ui_item_text: itemText,
      ui_item_type: TRACK_EVENT.ITEM_TYPE.SELECT,
      ui_item_url: TRACK_EVENT.ITEM_URL.NO_URL,
    });

  return (
    <>
      {teliaWifiRouter && (
        <TeliaWifiRouter
          isSelected={
            pageSelectedRequirements?.some((requirement) => requirement.productId === teliaWifiRouter.productId) ||
            false
          }
          onSelectClick={() => {
            pushTrackingEvent(TRACK_EVENT.ITEM_TEXT.TELIA_WIFI_ROUTER);
            setQuantityExtender(0);
            requirementSelectHandler(teliaWifiRouter);
          }}
          product={teliaWifiRouter}
        />
      )}

      {smartWifiRouter && (
        <SmartWifiRouter
          isSelected={
            pageSelectedRequirements?.some((requirement) => requirement.productId === smartWifiRouter.productId) ||
            false
          }
          onSelectClick={() => {
            pushTrackingEvent(TRACK_EVENT.ITEM_TEXT.SMART_WIFI_ROUTER);
            const isUnselectingSmartWifi =
              pageSelectedRequirements?.some((requirement) => requirement.productId === smartWifiRouter.productId) ||
              false;

            if (isUnselectingSmartWifi) setQuantityExtender(0);
            requirementSelectHandler(smartWifiRouter);
          }}
          product={smartWifiRouter}
        />
      )}

      {smartWifiRouter?.productId &&
        pageSelectedRequirements?.some((requirement) => requirement.productId === smartWifiRouter.productId) &&
        smartWifiExtender && (
          <SmartWifiExtender
            quantity={quantityExtender}
            maxQuantity={1}
            setQuantity={(args) => {
              if (typeof args === "function") {
                setQuantityExtender((prev) => {
                  const newValue = args(prev);
                  pushTrackingEvent(`quantityExtender ${newValue}`);
                  return prev;
                });
              }

              setQuantityExtender(args);
            }}
            product={smartWifiExtender}
          />
        )}

      {teliaPlayBox && (
        <TeliaPlayBox
          product={teliaPlayBox}
          isSelected={
            pageSelectedRequirements?.some((requirement) => requirement.productId === teliaPlayBox.productId) || false
          }
          onSelectClick={() => {
            pushTrackingEvent(TRACK_EVENT.ITEM_TEXT.TELIA_PLAY_BOX);
            requirementSelectHandler(teliaPlayBox);
          }}
        />
      )}

      {noBox && (
        <NoBox
          product={noBox}
          isSelected={
            pageSelectedRequirements?.some((requirement) => requirement.productId === noBox.productId) || false
          }
          onSelectClick={() => {
            pushTrackingEvent(TRACK_EVENT.ITEM_TEXT.NO_BOX);
            requirementSelectHandler(noBox);
          }}
        />
      )}

      {recording && (
        <Recording
          product={recording}
          isSelected={
            pageSelectedRequirements?.some((requirement) => requirement.productId === recording.productId) || false
          }
          onSelectClick={() => {
            pushTrackingEvent(TRACK_EVENT.ITEM_TEXT.RECORDING);
            requirementSelectHandler({ productId: recording.productId });
          }}
        />
      )}
    </>
  );
}
