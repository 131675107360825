import styled from "styled-components";
import { breakpoints, CollapseButton } from "@telia-no-min-side/components";

export const Column = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: ${breakpoints.lg}px;
  margin-bottom: 3rem;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 2 1 ${breakpoints.xs}px;
`;

export const SummaryWrapper = styled.div`
  flex: 1 1 330px;
`;

export const StyledCollapseButton = styled(CollapseButton)`
  margin: 0.5rem 0;
`;

export const QuantitySelectorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
`;
