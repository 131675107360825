import { ComponentProps, ReactNode, useEffect } from "react";
import { TopMenu } from "./TopMenu";
import { PickerButton } from "./PickerButton";
import { Icon } from "@telia/teddy";
import { TopMenuSkeleton } from "./TopMenuSkeleton";
import {
  BaseSubscription,
  isFixedCustomer,
  isMobileCustomer,
  NewProfiles,
  ProfileValue,
  useProfilePicker,
} from "components/profile-pickers/hooks/useProfilePicker";
import { useMobileAccount } from "hooks/useMobileAccount";
import { useSelectAccount } from "hooks/useSelectAccount";
import { useAuth } from "@telia-no-min-side/components";
import { format } from "@telia-no-min-side/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTH } from "util/constants/auth";
import { getFixedRoutes, getMobileRoutes } from "pages/broadband/top-section/broadbandRoutes";
import { isPricePlanMobileBroadband } from "util/format-subscription/isPricePlanMobileBroadband";
import { INTERNAL_LINK } from "util/constants/internalLinks";

export function getPrefferedBroadbandAccountTypeByPathname(pathname: string) {
  if (!pathname) return undefined;

  const fixedRoutes = getFixedRoutes({});

  if (fixedRoutes.some((route) => pathname.includes(route.link))) {
    return AUTH.ACCOUNT_TYPE.FIXED;
  }

  const mobileRoutes = getMobileRoutes();

  if (mobileRoutes.some((route) => pathname.includes(route.link))) {
    return AUTH.ACCOUNT_TYPE.MOBILE;
  }

  return undefined;
}

export function getIndexPath(profile: ProfileValue) {
  if (isMobileCustomer(profile)) {
    const mobileRoutes = getMobileRoutes();
    return mobileRoutes[0].link;
  } else {
    const fixedRoutes = getFixedRoutes({});
    return fixedRoutes[0].link;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mobileTitleFormatter(subscription: BaseSubscription) {
  return "M";
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mobileSubtitleFormatter(subscription: BaseSubscription) {
  return "Mobilt bredbånd";
}

function getProfileDisplay(profile: ProfileValue | undefined): ComponentProps<typeof TopMenu>["info"] {
  // const info = {
  //     title: format.startCase(`${selectedProfile?.value. || ""}`),
  //     subtitle: `Kundenummer: ${selectedProfile?.value.customerId}`,
  //   };

  if (isMobileCustomer(profile)) {
    return {
      title: "Mobilt bredbånd",
      subtitle: `Bredbåndsnummer: ${profile.phoneNumber.localNumber}`,
    };
  }

  if (isFixedCustomer(profile)) {
    return {
      title: format.startCase(`${profile?.street || ""}`),
      subtitle: `Kundenummer: ${profile?.customerId}`,
    };
  }

  return undefined;
}

/**
 * BroadbandPicker is used for switching between mobile broadband
 * and fixed broadband services. It will show all existing mobile broadband
 * services and all existing fixed customers (even when they don't have
 * broadband subscription).
 */

type Props = {
  dataTrackingID?: string;
  children?: ReactNode;
};

export function BroadbandTopMenu({ dataTrackingID, children }: Props) {
  const { newMobile: mobile, newFixed: fixed } = useProfilePicker({ mobileTitleFormatter, mobileSubtitleFormatter });

  const { setPhoneNumber, phoneNumber } = useMobileAccount();
  const { userInfo } = useAuth();
  const { setAccount, selectedAccountType, selectedFixedAccount } = useSelectAccount();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isIdentityUser = !!userInfo;

  const loading = mobile.loading || (fixed.loading && !fixed.profiles.length);
  const showRetry = !loading && ((mobile.hasError && isIdentityUser) || fixed.hasError);

  const mobileBroadbandProfiles = mobile.profiles.filter((profile) =>
    isPricePlanMobileBroadband(profile.value?.userOffering?.pricePlan)
  );

  const profiles = [...fixed.profiles, ...mobileBroadbandProfiles] as NewProfiles;

  const selectedProfileId =
    selectedAccountType === AUTH.ACCOUNT_TYPE.FIXED ? String(selectedFixedAccount?.data?.customerId) : phoneNumber;
  const selectedProfile = profiles.find((profile) => profile.id === selectedProfileId);

  useEffect(() => {
    if (loading || profiles.length === 0) {
      return;
    }

    if (mobileBroadbandProfiles.length === 0) {
      setAccount({ id: fixed.profiles[0].id, accountType: AUTH.ACCOUNT_TYPE.FIXED });
      return;
    }

    if (fixed.profiles.length === 0) {
      setAccount({ id: mobileBroadbandProfiles[0].value.account.id, accountType: AUTH.ACCOUNT_TYPE.MOBILE });
      if (!pathname.includes("mobilt-bredband")) {
        navigate("mobilt-bredband");
      }
      return;
    }

    if (pathname.includes("mobilt-bredband")) {
      setAccount({ id: mobileBroadbandProfiles[0].value.account.id, accountType: AUTH.ACCOUNT_TYPE.MOBILE });
      return;
    }

    setAccount({ id: fixed.profiles[0].id, accountType: AUTH.ACCOUNT_TYPE.FIXED });
  }, [loading]);

  const onRetry = () => {
    if (mobile.hasError) {
      mobile.refetch();
    }

    if (fixed.hasError) {
      fixed.refetch();
    }
  };

  const onProfileChange = (profile: ProfileValue) => {
    if (isMobileCustomer(profile)) {
      navigate("mobilt-bredband", { replace: true });
      setAccount({ id: profile.account.id, accountType: AUTH.ACCOUNT_TYPE.MOBILE });
      setPhoneNumber(profile.phoneNumber.localNumber);
    }

    if (isFixedCustomer(profile)) {
      if (selectedAccountType !== AUTH.ACCOUNT_TYPE.FIXED) {
        navigate(INTERNAL_LINK.FIXED.BROADBAND_HOME);
      }
      setAccount({ id: profile.customerId, accountType: AUTH.ACCOUNT_TYPE.FIXED });
    }
  };

  const info = getProfileDisplay(selectedProfile?.value);

  return loading ? (
    <TopMenuSkeleton dataTrackingID={dataTrackingID} />
  ) : (
    <TopMenu
      info={info}
      selectedOption={selectedProfile}
      options={profiles}
      handleOptionSelect={onProfileChange}
      dataTrackingID={dataTrackingID}
    >
      {children}
      {showRetry && (
        <PickerButton style={{ textWrap: "nowrap" }} subtext="Kan ikke laste" handleClick={onRetry}>
          <Icon size="sm" name="sync" />
        </PickerButton>
      )}
    </TopMenu>
  );
}
