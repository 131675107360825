import { BaseSyntheticEvent } from "react";
import { colors } from "@telia/styleguide";
import {
  ShoppingCard,
  ListItem,
  List,
  useAriaId,
  Collapse,
  useToggleWithGaTracking,
  Paragraph,
  Container,
  Button,
} from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { getSimplePriceText } from "src/util/priceFormatter";
import { StyledCollapseButton } from "./style";

type Props = {
  product: types.fixed.ProductGroupSingleProductWithShipping;
  isSelected: boolean;
  onSelectClick?: (e: BaseSyntheticEvent) => void;
  shouldDisableSelect?: boolean;
};

export function TeliaWifiRouter({ product, isSelected, onSelectClick, shouldDisableSelect }: Props): JSX.Element {
  const { isOpen, toggleIsOpen } = useToggleWithGaTracking(false, {
    onOpenTracking: {
      ui_item_action: "dropdown-click",
      ui_item_context: "buy-telia-wifi",
      ui_item_text: "Se spesifikasjoner",
      ui_item_type: "dropdown",
      ui_item_url: "no_url",
    },
  });
  const labelId = useAriaId();
  const collapseId = useAriaId();
  const { currentPrice, postCampaignPrice, campaignDuration } = product.price;
  const isCampaign = postCampaignPrice.recurring > currentPrice.recurring;

  return (
    <ShoppingCard
      title={product.name || "Wifi Router"}
      additionalTagText={
        <Paragraph>
          En router som gir gode opplevelser. Routeren leverer to ulike nett, som vil dekke både nye og gamle enheter.
        </Paragraph>
      }
      image={product.image}
      isSelected={isSelected}
      price={getSimplePriceText(currentPrice.recurring, currentPrice.chargePeriod) || "Finner ikke pris."}
      postCampaignPrice={isCampaign ? getSimplePriceText(postCampaignPrice.recurring) : undefined}
      campaignText={campaignDuration}
    >
      <ShoppingCard.Body>
        <Container>
          <StyledCollapseButton isOpen={isOpen} id={labelId} ariaControls={collapseId} toggleIsOpen={toggleIsOpen}>
            Se spesifikasjoner
          </StyledCollapseButton>
          <Collapse ariaLabelledby={labelId} id={collapseId} isOpen={isOpen}>
            <List spacing="small">
              <ListItem center icon="check-mark" iconColor={colors.green700}>
                Wifi 5 standard
              </ListItem>
              <ListItem center icon="check-mark" iconColor={colors.green700}>
                WPA 2 sikkerhetsstandard
              </ListItem>
              <ListItem center icon="check-mark" iconColor={colors.green700}>
                Støtter lynraske hastigheter med kabel og høye hastigheter over wifi
              </ListItem>
            </List>
          </Collapse>
        </Container>
      </ShoppingCard.Body>
      {onSelectClick && (
        <ShoppingCard.Footer>
          <Container floatContent="right" padding="top">
            <Button
              disabled={shouldDisableSelect}
              variant={isSelected ? "primary" : "secondary"}
              size="sm"
              onClick={onSelectClick}
            >
              {isSelected ? "Valgt" : "Velg"}
            </Button>
          </Container>
        </ShoppingCard.Footer>
      )}
    </ShoppingCard>
  );
}
