import { Breadcrumb } from "components/Breadcrumb";
import { AdditionalServices } from "pages/broadband/fixed/additional-services/AdditionalServices";

export function AdditionalServicesBroadband() {
  return (
    <>
      <Breadcrumb />
      <AdditionalServices />
    </>
  );
}
