import { types } from "@telia-no-min-side/utils";
import { Container, ShoppingSummary, ShoppingSummaryItemProps } from "@telia-no-min-side/components";
import { getSimplePriceText } from "src/util/priceFormatter";
import { productId } from "src/util/productIds";
import { sumPrices } from "util/prices";
import { getProductShipping } from "pages/broadband/fixed/buy-hardware/internet-equipment/shipping";
import { FlexWidth } from "../../styles/style";
import { useFlexOrder } from "../../context/FlexOrderProvider";
import { ShoppingCartSkeleton } from "./ShoppingCartSkeleton";
import { ShoppingCartSummary } from "./ShoppingCartSummary";
import { ShoppingCartActions } from "./ShoppingCartActions";
import { ShoppingCartError } from "./ShoppingCartError";
import { openPdf } from "util/pdf-order-summary/openPdf";
import { DownloadButton } from "components/pdf-order-summary/DownloadButton";
import { PdfHeader } from "components/pdf-order-summary/PdfHeader";
import { PdfFooter } from "components/pdf-order-summary/PdfFooter";

export type SelectedFlexRequirementsPrice = {
  totalRequiring: { amount: number; chargePeriod: string | undefined };
  recurring: ShoppingSummaryItemProps[];
  onceOff: ShoppingSummaryItemProps[];
  totalOnceOff: { amount: number };
  shippingOptionDTOS: types.fixed.ProductGroupShipping | undefined;
};

type Props = {
  hideShoppingCartHandler?: () => void;
};

export function ShoppingCart({ hideShoppingCartHandler }: Props) {
  const {
    quantityExtender,
    flexProductOffer,
    flexRequirementsData,
    flexRequirementsLoading,
    flexRequirementsError,
    selectedRequirementProductIds,
    resetFlexProductAndStartStep1,
  } = useFlexOrder();

  if (flexRequirementsLoading) {
    return <ShoppingCartSkeleton />;
  }

  if (flexRequirementsError || !flexRequirementsData) {
    return <ShoppingCartError handleClick={resetFlexProductAndStartStep1} />;
  }

  const selectedFlexRequirements = flexRequirementsData?.flatMap((requirement) =>
    requirement.options.filter(
      (option) =>
        selectedRequirementProductIds?.some(({ productId }) => {
          return productId === option.productId;
        }) ||
        (quantityExtender > 0 && option.productId === productId.smartWifiExtender)
    )
  );

  const initialChargePeriod = selectedFlexRequirements.find(
    (requirement) => requirement.price.currentPrice.chargePeriod
  )?.price.currentPrice.chargePeriod;

  const selectedFlexRequirementsPrice = selectedFlexRequirements.reduce<SelectedFlexRequirementsPrice>(
    (prev: SelectedFlexRequirementsPrice, requirement) => {
      if (!requirement.name) return prev;
      const isSmartWifiExtender = requirement.productId === productId.smartWifiExtender;
      const text = isSmartWifiExtender ? `${quantityExtender}x ${requirement.name}` : requirement.name;
      const { chargePeriod, onceOff, recurring } = requirement.price.currentPrice;
      const onceOffTotal = isSmartWifiExtender ? onceOff * quantityExtender : onceOff;
      const recurringTotal = isSmartWifiExtender ? recurring * quantityExtender : recurring;
      const totalRequiring =
        sumPrices(
          {
            amount: prev.totalRequiring.amount,
            chargePeriod: prev.totalRequiring.chargePeriod,
          },
          { amount: recurringTotal, chargePeriod }
        ) || prev.totalRequiring;
      const shippingOptionDTOS = getProductShipping(
        prev.shippingOptionDTOS ? [prev.shippingOptionDTOS] : undefined,
        requirement.shippingOptionDTOS
      );

      return {
        totalRequiring,
        recurring: [...prev.recurring, { price: getSimplePriceText(recurringTotal, chargePeriod), text }],
        onceOff: [...prev.onceOff, ...(onceOffTotal > 0 ? [{ price: getSimplePriceText(onceOffTotal), text }] : [])],
        totalOnceOff: { amount: prev.totalOnceOff.amount + onceOffTotal },
        shippingOptionDTOS,
      };
    },
    {
      totalRequiring: { amount: 0, chargePeriod: initialChargePeriod },
      recurring: [],
      onceOff: [],
      totalOnceOff: { amount: 0 },
      shippingOptionDTOS: undefined,
    }
  );

  const totalRecursiveAmount = sumPrices(
    {
      amount: flexProductOffer?.sum.currentAmount || 0,
      chargePeriod: flexProductOffer?.sum.chargePeriod,
    },
    selectedFlexRequirementsPrice.totalRequiring
  );
  const totalOnceOffAmount =
    selectedFlexRequirementsPrice.totalOnceOff.amount +
    (selectedFlexRequirementsPrice.shippingOptionDTOS?.onceOffPrice || 0);

  return (
    <FlexWidth id="flex-shopping-summary">
      <Container maxWidth="sm" flexDirection="column" gap>
        <PdfHeader />

        <ShoppingSummary.Wrapper maxWidth="sm">
          <ShoppingCartSummary
            selectedFlexRequirementsPrice={selectedFlexRequirementsPrice}
            totalRecursiveAmount={totalRecursiveAmount}
            totalOnceOffAmount={totalOnceOffAmount}
          />
          <Container data-html2canvas-ignore gap flexDirection="column">
            <DownloadButton mr="auto" onClick={async () => openPdf("#flex-shopping-summary")} />
            <ShoppingCartActions hideShoppingCartHandler={hideShoppingCartHandler} />
          </Container>
        </ShoppingSummary.Wrapper>

        <PdfFooter />
      </Container>
    </FlexWidth>
  );
}
