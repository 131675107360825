import { Dispatch, SetStateAction } from "react";
import { ShoppingCard, QuantitySelector, Skeleton, Paragraph } from "@telia-no-min-side/components";
import { types } from "@telia-no-min-side/utils";
import { useSmartWifiExtenderRestrictions } from "src/pages/broadband/fixed/hardware/utils/useSmartWifiExtenderRestrictions";
import { getSimplePriceText } from "src/util/priceFormatter";
import { QuantitySelectorWrapper } from "./style";

type Props = {
  product: types.fixed.ProductGroupSingleProductWithShipping;
  quantity: number;
  maxQuantity?: number;
  setQuantity: Dispatch<SetStateAction<number>>;
  shouldHideQuantitySelector?: boolean;
};

export function SmartWifiExtender(props: Props): JSX.Element {
  const { extenderRestrictions, hasMaxNumberOfExtenderRestriction, maxAllowedExtendersToOrder, isLoading } =
    useSmartWifiExtenderRestrictions();

  const { quantity, setQuantity, product, maxQuantity, shouldHideQuantitySelector } = props;
  const { currentPrice, postCampaignPrice, campaignDuration } = product.price;
  const isCampaign = postCampaignPrice.recurring > currentPrice.recurring;

  return (
    <ShoppingCard
      title={product?.name || "Smart Wifi Extender"}
      additionalTagText={
        <>
          <Paragraph>
            <strong>Sikrer optimal dekning i alle rom!</strong> <br /> Dette er anbefalt for deg som har en større bolig
            eller krevende dekningsforhold, f.eks. flere etasjer eller murvegger.
          </Paragraph>
          {typeof maxQuantity !== "number" && hasMaxNumberOfExtenderRestriction && (
            <Paragraph>
              Krever at du har vår Smart Wifi Router. Maks {extenderRestrictions?.max} stk per husholdning.
            </Paragraph>
          )}
        </>
      }
      image={product?.image}
      price={getSimplePriceText(currentPrice.recurring, currentPrice.chargePeriod) || "Finner ikke pris."}
      postCampaignPrice={isCampaign ? getSimplePriceText(postCampaignPrice.recurring) : undefined}
      campaignText={campaignDuration}
      additionalPriceText="per stk"
      isSelected={quantity > 0}
      selectedBadgeText="Valgt"
    >
      {!shouldHideQuantitySelector && (
        <ShoppingCard.Footer>
          {isLoading ? (
            <QuantitySelectorWrapper>
              <>
                <Skeleton variant="circular" />
                <Skeleton variant="rectangular" width="10ch" height="2rem" />
                <Skeleton variant="circular" />
              </>
            </QuantitySelectorWrapper>
          ) : (
            <QuantitySelectorWrapper>
              <QuantitySelector
                label="Velg antall Telia Smart Wifi Extenders"
                maxValue={maxQuantity ?? maxAllowedExtendersToOrder}
                minValue={0}
                quantity={quantity}
                setQuantity={setQuantity}
              />
            </QuantitySelectorWrapper>
          )}
        </ShoppingCard.Footer>
      )}
    </ShoppingCard>
  );
}
